@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    font-family: 'DM Sans', sans-serif;
}

/* common css start */
.flex-center {
    @apply flex justify-center items-center;
}
/* common css end */

/* Coming Soon Page Start */
.hero-root {
    @apply h-screen w-full p-4 2xl:p-8;
}
.hero-bg {
    @apply h-full bg-[url("./assets/images/hero_bg.jpg")] bg-no-repeat bg-center bg-cover rounded-[18px] overflow-y-auto;
}
.hero-wrapper {
    @apply w-full h-full flex flex-col justify-between items-center gap-8 pt-[40px] pb-[50px] lg:pb-[100px] px-[20px];
}
.hero-logo {
    @apply w-full flex-center gap-3;
}
.hero-section {
    @apply w-full flex-center flex-col;
}
.hero-section-tagline {
    @apply text-white text-2xl font-normal;
}
.hero-content-section {
    @apply flex-center flex-col text-center mt-[20px] lg:mt-[48px];
}
.ready-text {
    @apply text-white !text-[20px] md:!text-[28px] lg:!text-[32px] 2xl:!text-[40px] font-[500] leading-normal;
}
.ready-yellow-text {
    @apply text-[#ffe784] font-[400] leading-normal italic;
}
.ready-text-label {
    @apply text-white text-[12px] md:text-[20px] font-normal mt-2;
}
.email-section {
    @apply w-full md:w-[auto] flex flex-col md:flex-row items-center gap-2 py-[10px] lg:pt-[20px] xl:pt-[32px];
}
.email-input {
    @apply w-[100%] md:w-[320px] flex-[2] items-center outline-none rounded-xl bg-white px-[18px] py-[10px] md:py-[12px] lg:py-[14px] xl:py-[18px];
}
.sign-up-btn {
    @apply w-full flex-1 flex-center border-[#d82112] rounded-xl bg-[#d82112] hover:bg-white text-white hover:text-[#d82112] text-center text-[20px] font-[500] leading-6 transition-all duration-500 py-[10px] md:py-[12px] px-[18px] lg:py-[14px] xl:py-[18px] xl:px-[28px];
}
.loading-sign-up-btn {
    @apply sign-up-btn !text-[#d82112] !bg-white;
}
.sign-up-btn-loader {
    @apply h-6 w-6 border-2 border-solid border-[#d82112] border-t-transparent rounded-full animate-spin;
}
.premiun-section {
    @apply flex flex-col md:flex-row items-center gap-4 lg:gap-8 mt-[20px] lg:mt-0;
}
.premiun-avatar-wrapper {
    @apply flex -space-x-4 rtl:space-x-reverse;
}
.premiun-avatar {
    @apply w-10 h-10 border-2 border-white rounded-full;
}
.premiun-content {
    @apply text-center;
}
.premiun-text {
    @apply text-white text-[16px] text-center font-[400] leading-[22.4px];
}
.timer-section {
    @apply grid-cols-2 flex items-center gap-1 md:gap-2 lg:gap-3 2xl:gap-4 mt-[20px] 2xl:mt-[24px];
}
.timer-wrapper {
    background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.38) 0%,
        rgba(0, 0, 0, 0.24) 100%
    );
    @apply w-[65px] sm:w-[90px] lg:w-[120px] 2xl:w-[138px] flex flex-col items-center rounded-xl py-[7px] px-[10px] lg:px-[20px] xl:px-[30px] backdrop-blur-sm;
}
.time-number {
    @apply text-white !text-[20px] sm:!text-[30px] xl:!text-[50px] 2xl:!text-[63px] font-[700] leading-normal;
}
.time-label {
    @apply text-white text-[12px] lg:text-[22px] font-[400] leading-[30px];
}
.time-divider {
    @apply hidden sm:block text-white text-[53px] font-[700] leading-normal opacity-50;
}
/* Coming Soon Page end */

/* success modal start */
.modal-root {
    @apply fixed inset-0 z-50 flex-center p-4 md:p-8;
}
.modal-wrapper {
    @apply absolute inset-0 bg-gray-900 opacity-75;
}
.modal-content {
    @apply w-full md:w-[500px] flex-center flex-col gap-3 !bg-black text-white text-center rounded-xl shadow-md z-10 p-12;
    background: linear-gradient(
        0deg,
        rgba(216, 33, 18, 0.3) 0%,
        rgba(216, 33, 18, 0.1) 57.21%,
        rgba(216, 33, 18, 0) 99.57%
    );
}
.modal-title {
    @apply text-2xl font-bold leading-7;
}
.modal-subtitle {
    @apply text-base font-medium leading-7;
}
.modal-logo {
    @apply w-28 flex-center gap-3 mt-8;
}
/* success modal end */
